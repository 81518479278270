<template>
  <product-form
    :product-obj="product"
    @clearAction="action = null"
    :action="action"
  />
</template>

<script>
import ProductForm from "./components/ProductForm.vue";

export default {
  components: {
    ProductForm,
  },
  data() {
    return {
      action: "add",
      product: {
        status: null,
        date: new Date(),
        name: null,
        description: null,
        url: "",
        keywords: "",
        metaDescription: "",
      },
    };
  },
};
</script>
